
































































import mixins from 'vue-typed-mixins'
import {
  chapterStore,
  snackbarStore,
} from '@/store'
import ChapterCreateEditFields from '@/components/Chapter/CreateEditFields.vue'
import {
  Chapter,
} from '@/types/chapter'
import {
  Id,
} from '@/types/base'
import {
  newChapter,
} from '@/util/chapter'
import {
  form,
} from '@/mixins'
import omit from 'lodash.omit'

export default mixins(form)
  .extend({
    components: {
      ChapterCreateEditFields,
    },
    data: () => ({
      loadingSave: false,
      loadingDelete: false,
      editChapter: newChapter(),
    }),
    computed: {
      loadingGet (): boolean {
        return chapterStore.isLoadingGet
      },
      hasChanged (): boolean {
        const oldChapter = this.$copy(this.chapter)
        const editChapter = omit(oldChapter, [
          `id`,
        ])
        return !this.$isEqual<Chapter>(this.editChapter, editChapter)
      },
      chapter (): (Chapter & Id) {
        return chapterStore.dataById(this.$route.params.id) ?? {
          id: ``,
          ...newChapter(),
        }
      },
    },
    watch: {
      chapter: {
        immediate: true,
        handler (chapter: (Chapter & Id)) {
          this.reset(chapter)
        },
      },
    },
    async created () {
      await chapterStore.get(this.$route.params.id)
    },
    methods: {
      async save () {
        if (!this.validateForm()) return
        const editChapter: (Chapter & Id) = {
          id: this.$route.params.id,
          ...this.$copy(this.editChapter),
        }
        try {
          this.loadingSave = true
          await chapterStore.update(editChapter)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Chapter successfully updated`,
          })
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error updating chapter`,
          })
        }
        this.loadingSave = false
      },
      async deleteChapter () {
        if (!confirm(`Are you sure you want to delete "${this.chapter.name}"?`)) return
        try {
          this.loadingDelete = true
          await chapterStore.delete(this.$route.params.id)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Chapter successfully deleted`,
          })
          this.$router.go(-1)
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error deleting chapter`,
          })
        }
        this.loadingDelete = false
      },
      async reset (chapter: (Chapter & Id)) {
        const oldChapter = this.$copy(chapter)
        const editChapter = omit(oldChapter, [
          `id`,
        ])
        this.editChapter = editChapter
      },
    },
  })
